@use 'styles/variables' as var;
@use 'styles/functions' as fn;

//Mobile styles
.closer-statement-section {
  background-color: #30f;
  color: #fff;
  text-align: center;
  max-height: 520px;
  display: flex;
  overflow: hidden;
}

.outer-grid-container {
  padding-top: 32px;
}

.grid-item-text {
  order: 1;
}

.title {
  padding-bottom: 24px;
  line-height: 140%;
}

.cta-container .button {
  margin: 0 fn.spacing(0.3);
  padding: 12px 24px;
  z-index: 1;
}

.alt-cta {
  align-self: center;
  padding-top: fn.spacing(5);
}

.grid-item-image {
  order: 2;
  width: inherit; //critical for fitting image container to grid item container
  height: inherit; //critical for fitting image container to grid item container
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 24px;
}

.image {
  object-fit: contain;
  object-position: center bottom;
  max-height: 250px;
}

/* Desktop (Xlarge or greater) styles */
@media screen and (min-width: var.$breakpoint-xl) {
  .image {
    max-height: stretch;
  }

  .closer-statement-section {
    text-align: left;
    height: 339px;
  }

  .outer-grid-container {
    padding-top: 0;
    max-width: 920px;
    height: 339px;
    margin: auto;
  }

  .text-container {
    padding: 0;
    max-width: 450px;
  }

  .title {
    padding-bottom: 32px;
  }

  .cta-container {
    margin: 2px;
    display: flex;
  }

  .alt-cta {
    padding: 0 0 0 fn.spacing(5);
  }

  .cta-container .button {
    padding: 8px fn.spacing(2.5);
  }
}
